import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class GlobalClickService {

    @Output() newClick: EventEmitter<boolean> = new EventEmitter();
    @Output() bellKey: EventEmitter<boolean> = new EventEmitter();

    public mouseClick(): void {
        this.newClick.emit();
    }

    public onBellClick(): void {
        this.bellKey.emit();
    }

}