import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // Guard for user is login or not
        if (sessionStorage.getItem("Credential") || sessionStorage.getItem("SessionToken")) {
            return true;
        }
        else {
            let macAdd = "";
            if (localStorage.getItem("MacAddress")) {
                macAdd = localStorage.getItem("MacAddress");
            }
            this.router.navigate(['/login', macAdd]);
            return false;
        }
    }
}