import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from "ng-connection-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hellofreshBardoliPOS';

  static text: string = "";
  public classReference;
  status = "ONLINE";
  isConnected = true;

  constructor(private http: HttpClient,
    private ConnectionService: ConnectionService) {
      this.ConnectionService.monitor().subscribe(isConnected => {
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.status = "ONLINE";
          //console.log(this.status);
        } else {
          this.status = "OFFLINE";
          //console.log(this.status);
        }
      });
    }

  async ngOnInit() {
    this.classReference = AppComponent;

    let obj = await this.http.get('./../assets/variable.json').toPromise() as any;
    if (obj && obj.apiUrl) {
      environment.apiUrl = obj.apiUrl;

      ///https://api.hellofreshbardoli.com
    }
  }
}
