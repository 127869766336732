import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';


const routes: Routes = [
  //{ path: 'login/:macAdd', loadChildren: () => import('./view/middleware/middleware.module').then(m => m.MiddlewareModule) },
  { path: '', loadChildren: () => import('./view/view.module').then(m => m.ViewModule), canActivate: [AuthGuard] },
  //{ path: 'sale/add', loadChildren: () => import('./view/addsale/addsale.module').then(m => m.AddSaleModule), canActivate: [AuthGuard] },
  { path: 'sale/add', loadChildren: () => import('./view/secondlayout/secondlayout.module').then(m => m.SecondLayoutModule), canActivate: [AuthGuard] },
  { path: 'login/:macAdd', loadChildren: () => import('./common/login/login.module').then(m => m.LoginModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
